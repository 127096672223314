@use "sass:color";

html {
    height: 100vh;
}

body {
    font-family: Nunito, sans-serif;
    background-color: $white;
    font-size: 1rem;
    padding-bottom: 36px;
    background-size: 100vw 100vh;
    background-position: center;
    background-repeat: no-repeat;
}

h2 {
    font-weight: 700;
}

.content {
    img, video {
        width: 100%;
        height: auto;
    }
    .cat-icon {
        width: 24px;
        vertical-align: top;
    }
    .description img {
        width: 100% !important;
        height: auto !important;
    }
    margin-bottom: 50px;
}


.btn {
    background-color: $jgagreen;
    border-color: $jgagreen;
    font-weight: 700;

    &:hover,
    &:focus, &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: $gameinmotion;
        border-color: $gameinmotion;
    }

    &:disabled, &.disabled {
        background-color: rgba($gray-600, 0.5);
        border-color: rgba($gray-600, 0.5);
    }

    &-warning {
        color: #212529;
        background-color: #ffed4a;
        border-color: #ffed4a;
    }

    &-success {
        color: #ffffff;
        background-color: #38c172;
        border-color: #38c172;
    }

    &-secondary {
        background-color: #91687f;
        border-color: #91687f;
    }

    &-info {
        color: #ffffff;
        background-color: #335abb;
        border-color: #335abb;
    }
}

.form-control {
    border: none;
    border-bottom: 0.225rem solid #91687f;
    border-radius: 0;
    font-size: 1.0625rem;
    padding-top: 0.825rem;
    .login {
        border-bottom: 0.225rem solid $jgagreen;
    }
    &:focus {
        color: #495057;
        background-color: $white !important;
        border-color: $jgagreen;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($jgagreen, 0.25);
    }
}

.booking_form {
    .form-control {
        padding: .375rem .75rem;
    }
    .select2-container--default .select2-selection--single {
        border: none;
        font-size: 1.0625rem;
        width: 100%;
        height: 2.2rem;
        padding: .375rem .75rem;
        font-weight: 400;
        line-height: 1.6;
    }

    .code.server {
        text-align: center;
        padding: 10px 10px 0 10px;
        min-height: 60px;

        #paypal-button-container {
            margin-top: 20px;
            width: 100%;
            display: inline-block;
        }

        @media (min-width: 766px) {
            #paypal-button-container {
                width: 400px;
            }
        }
    }
}

#digital-payment-checkout {
    .headline, .info-box {
        text-align: center;
    }

    .booking-data {
        position: relative;
        display: block;
        margin-inline: auto;
        width: 100%;
        min-width: 200px;
        max-width: 750px;

        .row > div {
            background-color: rgba(255, 255, 255, 0.3);
            padding-block: 2%;
            border-radius: 4px;

            box-sizing: border-box;
            border: 5px solid transparent;
            background-clip: padding-box;
        }
    }
}
.booking-data {
    #{headings()} {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .price {
        font-size: 1.2rem;
        border-bottom: 6px dotted;
        display: inline-block;
    }
}

.progress {
    margin-bottom: 1rem;
    background-color: rgba(145, 104, 127, .2);
    border-radius: 20px;
    height: 1.2rem;

    .progress-bar {
        background-color: transparent;
        &.active {
            background-color: rgba(145, 104, 127, 1);
            border-radius: 20px;
            height: 1.2rem;
        }
    }
}


.custom-switch {
    padding-left: 0;
    padding-right: 2.25rem;

    .custom-control-label {
        font-weight: 700;
        width: 100%;
        margin-bottom: .5rem;

        span {
            display: block;
        }

        &::before {
            left: auto;
            right: -2.25rem;
            width: 2.45rem;
            height: 1.3rem;
            border-radius: 1.3rem;
        }

        &::after {
            background-color: rgb(208, 75, 53);
            left: unset;
            top: 0.22rem;
            right: -1.15rem;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 1.3rem;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            border-color: $jgagreen;
            background-color: $jgagreen;
            transform: translateX(1.15rem);
        }
        &::before {
            border-color: rgb(173, 181, 189);
            background-color: $white;
        }
    }
}


#countdown {
    box-shadow: 0 1px 2px 0 rgba(1, 1, 1, 0.4);
    width: 240px;
    height: 150px;
    text-align: center;
    background: #f1f1f1;
    border-radius: 5px;
    margin: auto;

    .tiles {
        color: #fff;
        position: relative;
        z-index: 1;
        text-shadow: 1px 1px 0px #ccc;
        display: inline-block;
        font-family: Arial, sans-serif;
        text-align: center;

        padding: 20px;
        border-radius: 5px 5px 0 0;
        font-size: 48px;
        font-weight: thin;
        display: block;
    }
}

div#emendo_countdown button {
    color: red;
    background-color: blue;
}

.color-full {
    background: #53bb74;
}

.color-half {
    background: #ebc85d;
}

.color-empty {
    background: #e5554e;
}

#countdown .tiles > span {
    width: 70px;
    max-width: 70px;
    padding: 18px 0;
    position: relative;
}

#countdown .labels {
    width: 100%;
    height: 25px;
    text-align: center;
    position: absolute;
    bottom: 8px;

    li {
        width: 102px;
        font: bold 15px 'Droid Sans', Arial, sans-serif;
        color: #f47321;
        text-shadow: 1px 1px 0px #000;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
    }
}

.bg-category- {
    &1 {
        background-color: #91687F;
    }
    &2 {
        background-color: #3CAFB4;
    }
    &3 {
        background-color: #F7A395;
    }
    &4 {
        background-color: #30534F;
    }
    &5 {
        background-color: #5E505A;
    }
    &6 {
        background-color: #D67261;
    }
    &7 {
        background-color: #3C777A;
    }
    &8 {
        background-color: #266259;
    }
    &9 {
        background-color: #3C777A;
    }
    &10 {
        background-color: #3CAFB4;
    }
    &11 {
        background-color: #91687F;
    }
    &12 {
        background-color: #30534F;
    }
    &13 {
        background-color: #266259;
    }
    &14 {
        background-color: #266259;
    }
    &15 {
        background-color: #202828;
    }
    &16 {
        background-color: #3f801b;
    }
}

.solved-1 {
    opacity: .4;
}

.point-bar {
    background-color: $jgagreen;
    color: $white;
    padding: 15px 15px 0 15px;
    .progress {
        background-color: $white;
        border-radius: 5px;
        .progress-bar {
            &.active {
                border-radius: 5px;
                background-color: #fac0b7;
            }
        }
    }
}

.map-container,
.point-bar {
    margin-right: -15px;
    margin-left: -15px;
}

.map {
    width: 100%;
    height: calc(100vh - 50px);
}

#map:focus {
    outline: #4A74A8 solid 0.15em;
}

.locate {
    top: 6em;
    left: .5em;
}

#answerModal {
    .modal-title {
        font-size: 2rem;
    }
}

#in-range {
    display: none;
}

.click-to-solve {
    display: none;
}

#help-me {
    float: right;
    right: 10px;
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    i {
        font-size: 20px;
    }
}

#unity_iframe {
    border: 0;
    height: 80vh;
    max-height: 80vh !important;
    width: 95vw
}

body.map #timeout-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

footer i {
    font-size: 1.5rem;
}

.compass {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px auto;
    .disc {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 200;
        background: url('/images/compass.png');
        width: 300px;
        height: 300px;
        background-size: 100%;
    }
}

.distance {
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.polaroid {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    margin-bottom: 1rem;
}

#endtimeclock {
    font-weight: bold;
}

.fa-grin-wink,
.fa-sad-tear {
    font-weight: 900;
}

.placeholder {
    position: absolute;
    top: 0px;
    left: 15px;
    pointer-events: none;
    width: 92%;
}

@media (min-width: 992px) {
    .hidden-lg, .hidden-md {
        display:none;
    }
}

input[type=checkbox] {
    min-width: 20px;
}


#homeScreenInstall .modal-title,
#homeScreenInstall .modal-body > div {
    display: none;
}

#gpsAccuracyNote,
#distanceNote {
    position: absolute;
    top: 106px;
    z-index: 999;
    left: 11px;
}

#gpsAccuracy {
    top: 160px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 17px;
    position: absolute;
    top: 1px;
    right: 8px;
    width: 20px;
    b  {
        position: relative;
        border: none;
        font-size: 10px;
        &:before {
            content: "\F078";
            display: inline-block;
            font-family: var(--fa-style-family, "Font Awesome 6 Free");
            position: absolute;
            right: -1.5em;
        }
    }
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:before {
    content: "\F077";
}

.flatpickr-mobile:before  {
    content: attr(placeholder);
    position: absolute;
    background-color: white;
}
.flatpickr-mobile:focus[value]:not([value=""]):before {
    display: none;
}

input[type="hidden"][value]:not([value=""]) + .flatpickr-mobile:before {
    display: none;
}

.team-name:first-of-type .sub-team-btn {
    display: none;
}

#teams .helper-block {
    display: none;
}

#scoreboard .team {
    margin-inline: 0;
    font-size: 1.3rem;
}

#scoreboard .team .col-10,
#scoreboard .team .col-2 {
    background-color: #DDDDDD;
}

.check-toggle {
    .btn-outline-success {
        color: black;
        background-color: transparent;
        border-color: green;
    }
    .btn-check {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
    }
    .btn-check:checked+.btn,
    :not(.btn-check)+.btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        color: black;
        background-color: green;
        border-color: green;
    }
}


#recaptcha-container
{
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}
.g-recaptcha-bubble-arrow
{
    display: none;
}
.g-recaptcha-bubble-arrow + div
{
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
