/* nunito-300 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Nunito/nunito-v16-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/Nunito/nunito-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Nunito/nunito-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Nunito/nunito-v16-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Nunito/nunito-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Nunito/nunito-v16-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Nunito/nunito-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/Nunito/nunito-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Nunito/nunito-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Nunito/nunito-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Nunito/nunito-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Nunito/nunito-v16-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/Roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/Roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* cinzel - latin */
@font-face {
    font-family: 'Cinzel';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Cinzel/cinzel-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/Cinzel/cinzel-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Cinzel/cinzel-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Cinzel/cinzel-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Cinzel/cinzel-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Cinzel/cinzel-v16-latin-regular.svg#Cinzel') format('svg'); /* Legacy iOS */
}
/* patua-one - latin */
@font-face {
    font-family: 'Patua One';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/PatuaOne/patua-one-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/PatuaOne/patua-one-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/PatuaOne/patua-one-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/PatuaOne/patua-one-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/PatuaOne/patua-one-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/PatuaOne/patua-one-v15-latin-regular.svg#PatuaOne') format('svg'); /* Legacy iOS */
}
/* patua-one - latin */
@font-face {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/SpaceMono/space-mono-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/SpaceMono/space-mono-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/SpaceMono/space-mono-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/SpaceMono/space-mono-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/SpaceMono/space-mono-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/SpaceMono/space-mono-v10-latin-regular.svg#SpaceMono') format('svg'); /* Legacy iOS */
}
